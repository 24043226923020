import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Button from "../../components/custom-widgets/button";
import ModalGeneric from "../../components/custom-widgets/modal-generic";
import LoginHelpModalData from "../../components/login/login-help-modal-data";
import Icon from "../../components/custom-widgets/icon";
import PersonalizationCards from "../../components/card/personalization-cards";
import HeroChevron from "../../components/hero/hero-chevron";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const Login = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/business-banking/login/hero-business-login-110824-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const handleCloseHelpModal = () => setShowHelpModal(false);
  const handleShowHelpModal = (e) => {
    e.preventDefault();
    setShowHelpModal(true);
  };

  const heroChevronData = {
    id: "business-banking-login-hero",
    ...getHeroImgVariables(imgData),
    altText: "Happy businessman walking outside building.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Run Your Business Better with WaFd"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Solutions for small and large enterprises."
          }
        },
        {
          id: 3,
          button: {
            id: "business-login-hero-cta",
            class: "btn-white",
            url: "/business-banking/business-services",
            text: "Learn More"
          }
        }
      ]
    }
  };

  const seoData = {
    title: "WAFD Treasury Express Login - Small Business Banking",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "WAFD Treasury Express Login - Small Business Banking"
      },
      {
        name: "description",
        content:
          "Login to WAFD Treasury Express, your small business account with WaFd Bank (formally known as Washington Federal). Access your business checking accounts today."
      },
      {
        property: "og:title",
        content: "WAFD Treasury Express Login - Small Business Banking"
      },
      {
        property: "og:description",
        content:
          "Login to WAFD Treasury Express, your small business account with WaFd Bank (formally known as Washington Federal). Access your business checking accounts today."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/login"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-login-110824.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "WAFD Treasury Express Sign In"
    }
  ];

  const signInBtn = {
    id: "business-banking-sign-in-btn",
    externalLink: true,
    class: "btn-access-green btn-block no-min-width no-max-width",
    text: "Sign In",
    containerClass: "mb-3",
    showIcon: false,
    url: "https://commercial.washingtonfederal.com/smallbusiness/auth"
  };

  const loginHelpModalData = {
    handleClose: handleCloseHelpModal,
    show: showHelpModal,
    modalSize: "md",
    header: {
      id: "cc-sign-in-help-modal-title",
      class: "mb-0",
      text: "Sign In Help"
    },
    body: {
      class: "",
      content: [<LoginHelpModalData />]
    }
  };

  const RightArrow = () => {
    return <Icon name="arrow-right" lib="far" class="ml-1" />;
  };

  const personalizationCardData = {
    defaultCardsArray: [1]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <SEO {...seoData} />
      {showHelpModal && <ModalGeneric {...loginHelpModalData} />}
      <section className="container section-padding-adjusted">
        <h1>Sign in to your WaFd Treasury Express account</h1>
        <div className="row justify-content-between">
          <div className="col-md-6 col-xl-8 mb-3">
            <div className="row">
              <div className="col-lg-10 col-xl-6">
                <Button {...signInBtn} />
                <div className="row justify-content-between">
                  <div className="col-auto">
                    <p id="default-first-time-signup-personal">
                      First-time user?{" "}
                      <a id="business-enroll-link" href="https://business.washingtonfederal.com/enrollment/">
                        Enroll Now
                      </a>
                    </p>
                  </div>
                  <div className="col text-right">
                    <button
                      id="btn-show-help-modal"
                      onClick={handleShowHelpModal}
                      className={`anchor-link text-decoration-none btn-anchor-link`}
                    >
                      <Icon name="question-circle" lib="far" class="mr-1" />
                      Help
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <h4 className="font-weight-bold">Looking for Commercial Banking?</h4>
              <Link id="commercial-login-page-link" to="/commercial-banking/login" className="text-decoration-none">
                Login to WAFD Treasury Prime
                <RightArrow />
              </Link>
            </div>
            <div className="mb-3">
              <h4 className="font-weight-bold">Using Personal Banking for Business?</h4>
              <Link id="personal-login-page-link" to="/personal-banking/login" className="text-decoration-none">
                Login to Personal Banking
                <RightArrow />
              </Link>
            </div>
            <div className="mb-3">
              <Link
                id="treasury-express-page-link"
                to="/commercial-banking/treasury-management-services/express"
                className="text-decoration-none"
              >
                Learn more about Treasury Express
                <RightArrow />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <PersonalizationCards {...personalizationCardData} />
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Login;
